



















































































































































































































































import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator'
import { FormaDePagamento } from '@/models'
import type { TipoDePagamento } from '@/models'
import { obrigatorio } from '@/shareds/regras-de-form'
import SeletorDeFormaDePagamento from './SeletorDeFormaDePagamento.vue'
import { tipoDePagamentoTemRecorrencia } from '@/shareds/loja-shareds'
import DialogoDeTaxas from './DialogoDeTaxas.vue'

@Component({
	components: {
		SeletorDeFormaDePagamento,
		DialogoDeTaxas,
	},
})
export default class DialogoDeTipoDePagamento extends Vue {
	@Prop() value!: boolean
	@Prop() temTipoPagamentoConsignado!: boolean
	@Prop() temTipoPagamentoDemonstracao!: boolean
	@Ref() form!: HTMLFormElement
	@Ref() dialogoTaxas!: DialogoDeTaxas
	@Ref() campoDeNome!: HTMLInputElement
	@Prop({ type: Object, default: criarTipoDePagamento }) initialValue!: TipoDePagamento
	@Prop({ type: Boolean, default: false }) disabled!: boolean
	@Prop({ type: Boolean, default: false }) readonly!: boolean
	@Prop({ type: Boolean, default: false}) mostraPagamentoTEF!: boolean
	
	tipoDePagamento: TipoDePagamento = criarTipoDePagamento()
	parcela = 0
	obrigatorio = obrigatorio
	tipoDePagamentoTemRecorrencia = tipoDePagamentoTemRecorrencia

	@Watch('value')
	onChangeMostra(mostra: boolean) {
		if (!mostra) return
		this.tipoDePagamento = JSON.parse(JSON.stringify(this.initialValue))
		setTimeout(() => {
			this.form.resetValidation()
			setTimeout(() => {
				this.campoDeNome.focus()
			})
		})
	}
	get mostrarTipoPagamentoConsignado() {
		return !(this.tipoDePagamento.formaDePagamento === 'Consignado') && this.temTipoPagamentoConsignado
	}
	get mostrarTipoPagamentoDemonstracao() {
		return !(this.tipoDePagamento.formaDePagamento === 'Demonstração') && this.temTipoPagamentoDemonstracao
	}
	get mostrarTipoPagamentoValePresente() {
		return !(this.tipoDePagamento.formaDePagamento === 'Vale Presente')
	}

	confirmar() {
		if (!this.form.validate()) return
		if(this.tipoDePagamento.formaDePagamento !== "Crediário") this.tipoDePagamento.crediarioDoFuncionario = false 
	

		this.tipoDePagamento.exigeCodAutorizacao = this.tipoDePagamento.formaDePagamento === 'Cartão' ? this.tipoDePagamento.exigeCodAutorizacao : false
		this.tipoDePagamento.exigeNsu = this.tipoDePagamento.formaDePagamento === 'Cartão' ? this.tipoDePagamento.exigeNsu : false
		this.tipoDePagamento.exigeBandeira = this.tipoDePagamento.formaDePagamento === 'Cartão' ? this.tipoDePagamento.exigeBandeira : false
		this.tipoDePagamento.exigeAdquirente = this.tipoDePagamento.formaDePagamento === 'Cartão' ? this.tipoDePagamento.exigeAdquirente : false

		this.$emit('confirma', this.tipoDePagamento)
		this.$emit('input', false)
		this.parcela = 0
	}
	get parcelas() {
		return this.tipoDePagamento.parcelasComTaxas != null ? 
			this.tipoDePagamento.parcelasComTaxas.map(item => item.parcelamentos).sort((pA, pB) =>{
				if( pA < pB) return -1
				if( pA > pB) return 1
				return 0
			}) : null
	}

	subtractParcela() {
		if(!this.tipoDePagamento.parcelasComTaxas)return
		this.tipoDePagamento.parcelasComTaxas = this.tipoDePagamento.parcelasComTaxas.sort((pA, pB) =>{
			if( pA.parcelamentos < pB.parcelamentos) return -1
			if( pA.parcelamentos > pB.parcelamentos) return 1
			return 0
		})
		const idx = this.tipoDePagamento.parcelasComTaxas.length - 1
		this.tipoDePagamento.parcelasComTaxas.splice(idx, 1)
		
		this.parcela = this.tipoDePagamento.parcelasComTaxas.length - 1
	}

	addParcela() {
		if(this.tipoDePagamento.parcelasComTaxas === null) return
		this.parcela = this.tipoDePagamento.parcelasComTaxas.length + 1
		this.tipoDePagamento.parcelasComTaxas.push({
			taxas: 0,
			parcelamentos: this.parcela,
			recorrencia: this.tipoDePagamento.diasDeRecorrencia,
		})
	}

	abrirDialogoTaxa() {
		this.dialogoTaxas.mostrar()
	}

	@Watch('tipoDePagamento.formaDePagamento')
	onChangeTipoDePagamento() {
		if (this.tipoDePagamento.formaDePagamento === 'Demonstração') {
			this.tipoDePagamento.nome = 'Demonstração'
			this.tipoDePagamento.crediarioDoFuncionario = false
			this.tipoDePagamento.diasDeRecorrencia = 30
			this.tipoDePagamento.exigeBandeira = false
			this.tipoDePagamento.exigeNsu = false
			this.tipoDePagamento.exigeCodAutorizacao = false
			this.tipoDePagamento.exigeAdquirente = false
			this.tipoDePagamento.utilizaPlugin = false
			this.tipoDePagamento.parcelasComTaxas = []
			this.tipoDePagamento.transacoes = ['Venda','Devolução']
		}
	}

}

export function criarTipoDePagamento(formaDePagamento?: FormaDePagamento): TipoDePagamento {
	return {
		id: '',
		nome: '',
		formaDePagamento: !formaDePagamento ? 'Cartão' : formaDePagamento,
		parcelasComTaxas: [],
		diasDeRecorrencia: 30,
		transacoes: ['Venda'],
		crediarioDoFuncionario: true,
		exigeCodAutorizacao: false,
		exigeNsu: false,
		exigeBandeira: false,
		exigeAdquirente: false,
		baixarContas:false,
		fazParteComissao: false,
		utilizaPlugin: false,
		exibirNasDuplicatasDaDanfe: false,
		alterarVencimentoCrediario: false,
		parcelasCrediario: null,
	}
}

